import React from 'react'
import { Link } from 'react-router-dom'

export default function Pricing() {
  return (
    <>
    <br/><br/><br/><br/>
      <section id="pricing" className="pricing">
      <div className="container">

        <div className="section-title">
          <h2>WEBSITE DEVELOPMENT PLANS & PRICING</h2>
          <p>WE PROVIDE BEST SERVICE AT VERY AFFORDABLE PRICE</p>
            

        </div>

        <div className="row">

          <div className="col-lg-3 col-md-6">
            <div className="box">
              <h3>Basic Plan</h3>
              <h4><sup>₹</sup>4999<span> /One Time</span></h4>
              <ul>
                <li>5 Page (Dynamic Website)</li>
                <li> SEO Ready Website</li>
                <li>100% Responsive Website</li>
                <li className="na">Live Chat Integration</li>
                <li className="na"> Social Media Integration</li>
                <li className="na"> Payment Gateway Integration</li>
                
              </ul>
              <div className="btn-wrap">
                <Link to="/contact" className="btn-buy">Hire</Link>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-md-0">
            <div className="box featured">
              <h3>Silver Plan</h3>
              <h4><sup>₹</sup>7999<span> /One Time</span></h4>
              <ul>
                <li>10 Page (Dynamic Website)</li>
                <li> SEO Ready Website</li>
                <li>100% Responsive Website</li>
                <li>Live Chat Integration</li>
                <li className="na"> Social Media Integration</li>
                <li className="na"> Payment Gateway Integration</li>
              </ul>
              <div className="btn-wrap">
                <Link to="/contact" className="btn-buy">Hire</Link>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div className="box">
              <h3>Gold Plan</h3>
              <h4><sup>₹</sup>14999<span> /One Time</span></h4>
              <ul>
                <li>25 Page (Dynamic Website)</li>
                <li> SEO Ready Website</li>
                <li>100% Responsive Website</li>
                <li>Live Chat Integration</li>
                <li> Social Media Integration</li>
                <li className="na"> Payment Gateway Integration</li>
              </ul>
              <div className="btn-wrap">
                <Link to="/contact" className="btn-buy">Hire</Link>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div className="box">
              <span className="advanced">Advanced</span>
              <h3>Premium Plan </h3>
              <h4><sup>₹</sup>19999+<span>As Deal</span></h4>
              <ul>
                <li>UL Pages* (Dynamic Website)</li>
                <li> SEO Ready Website</li>
                <li>100% Responsive Website</li>
                <li>Live Chat Integration</li>
                <li> Social Media Integration</li>
                <li > Payment Gateway Integration</li>
              </ul>
              <div className="btn-wrap">
                <Link to="/contact" className="btn-buy">Hire</Link>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    </>
  )
}
