import React from 'react'

export default function Contact() {

  async function getText()

  
  {
    let name=document.getElementById("name").value;
    let email=document.getElementById("email").value;
    let number=document.getElementById("number").value;
    let msg=document.getElementById("msg").value;
    document.getElementById("loding").classList.remove("loading")
    document.getElementById("loadingt").innerText="Loading..."
   
    let x = await fetch('https://gwd.epizy.com/mailapi/mail.php',{
      method: 'POST',
      headers: {
        
        'Content-Type': 'application/x-www-form-urlencoded'
        
      },
      body: `name=${name}&email=${email}&number=${number}&msg=${msg}`
    });
    let y = await x.text();
    let res=JSON.parse(y);
   // console.log(res);
    document.getElementById("loding").classList.add("loading")
    document.getElementById("loadingt").innerText="Send Message"
if(res.code==1){
  document.getElementById("send").classList.remove("sent-message");
  document.getElementById("myfrom").reset();
 document.getElementById("send").innerHTML=res.msg;
 document.getElementById("error").innerHTML='';
 setTimeout(() => {
  document.getElementById("send").innerHTML='';
 }, 10000);
}if(res.code==0){
  document.getElementById("error").classList.remove("error-messag");
  document.getElementById("error").innerHTML=res.msg;
   document.getElementById("send").innerHTML='';



}


  }
  return (
    <>
    <br/><br/><br/>
      <section id="contact" className="contact">
      <div className="container">

        <div className="section-title">
          <h2>Contact</h2>
          <p>We Are Aailable  For You 24/7 Please feel free to contact us & we would be happy to assist you!</p>
        </div>

        <div className="row">

          <div className="col-lg-6">

            <div className="row">
              <div className="col-md-12">
                <div className="info-box">
                  <i className="bx bx-map"></i>
                  <h3>Our Address</h3>
                  <p>Anupgarh,Sri Ganganagar,Rajasthan India-335701</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4">
                  <i className="bx bx-envelope"></i>
                  <h3>Email Us</h3>
                  <p>Guri77566@gmail.com</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4">
                  <i className="bx bx-phone-call"></i>
                  <h3>Call Us</h3>
                  <p>+918696671521</p>
                </div>
              </div>
            </div>

          </div>

          <div className="col-lg-6"><div className="my-3">
                
                <div id="error"className="error-messag text-center text-danger"></div>
                <div id="send" className="sent-message text-center text-warning "></div>
              </div>
            <form  method="" role="form" id="myfrom" className="php-email-form" onSubmit={(e)=>{e.preventDefault()
            getText();
            }}>
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" name="number" id="number" placeholder="Your Mobile" required/>
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="msg" id="msg" rows="5" placeholder="Message" required></textarea>
              </div>
              
              <div className="text-center my-3"><button type="submit" ><span id="loadingt" >Send Message</span><span id="loding" className="loading spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  </button></div>
            </form>
          </div>
          

        </div>

      </div>
    </section>
    </>
  )
}
