import React from 'react'
import Header from './pages/Header'
import './App.css';
import Footer from './pages/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import About from './pages/About';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import { Route, Routes } from 'react-router-dom';
import Notfound from './pages/Notfound';

function App() {
  return (
    <>
    <Header/>
<Routes>
<Route exact path="/" element={<Home />} />
          
          <Route exact path="/about" element={<About/>} />
          <Route  exact path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/term-condition" element={<Terms/>} />
          
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="*" element={<Notfound/>} />
</Routes>

<Footer/>


</>
    
  );
}

export default App;
