import React from 'react'
import About1 from '../img/about1.jpg'
import About2 from '../img/about2.jpg'
import Aboutlink from '../img/about.jpg'
import php from "../img/homeimg/php.png";
import js from "../img/homeimg/js.png";
import boot from "../img/homeimg/boot.png";
import css from "../img/homeimg/css.jpg";
import jq from "../img/homeimg/jquery.png";
import reactlink from "../img/homeimg/react.png";
export default function About() {
  return (
    <>
      <br/><br/> <br/><br/>
    <section id="clients" className="clients section-bg">
      <div className="container">

        <div className="row">

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={reactlink} className="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={php} className="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={js} className="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={jq} className="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={boot} className="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={css} className="img-fluid" alt=""/>
          </div>

        </div>

      </div>
    </section>
    <section id="about" className="about">
      <div className="container">

        <div className="row">
          <div className="col-lg-6">
            <img src={Aboutlink} style={{borderRadius:"20px",
             }} className="img-fluid" alt=""/>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <h3>Creating The Website of Your Dreams</h3>
            <p>

            
            </p>
            <div className="row">
              <div className="col-md-12">
                <i className="bx bx-receipt"></i>
                
              <p>Guri web developer is a leading website designing company in rajasthan that offers one-stop business solutions. We take great satisfaction in providing a wide range of services, such as website design, website development, e-commerce development, digital marketing, performance marketing, domain hosting, graphic design, and bulk messaging services.</p>
              <  p>Our management procedures work to guarantee prompt delivery, client contentment, creative solutions, and top-notch services. With top-tier technologies, we have excelled</p>
              </div>
              
            </div>
          </div>
        </div>

      </div>
    </section>
    <section id="about" className="about">
      <div className="container">

        <div className="row">
          
          <div className="col-lg-6 pt-4 pt-lg-0">
            <h3>We Create, Design and Make it Real</h3>
            
            <div className="row">
              <div className="col-md-12">
                
                <p>Guri Web design & Development company offering a complete set of website designing, website development for our clients in India and abroad since 2019. We have handled each and every project of ours with high level of commitment taking great care of quality.

WE foces is web designing, web hosting, e-commerce solutions, Digital marketing(SEO) for all sizes and type of companies be it small, medium or large scale industry. In such a short span of time we have captured the local market and established our own brand. Our highly creative team of designers creates customized, attractive, customer friendly websites to help our clients achieve their end target and increase their customer base. We create all types of B2B and B2C Web Portals, Advanced Ecommerce websites, E-learning websites, Online Management Systems, Customized Web Applications etc.</p>
              </div>
             
            </div>
          </div>
          <div className="col-lg-6">
            <img src={About1} style={{borderRadius:"20px",
             }} className="img-fluid" alt=""/>
          </div>
        </div>

      </div>
    </section>
    <section id="about" className="about">
      <div className="container">

        <div className="row">
          <div className="col-lg-6">
            <img src={About2} style={{borderRadius:"20px",
              }} className="img-fluid" alt=""/>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <h3>Why choose us </h3>
            <p>

            
            </p>
            <div className="row">
              <div className="col-md-12">
                
              <p> <b>We are best at everything we do:</b> We have the team of best designers, developers who can create anything from stratch as per our clients requirement. We just dont create websites, they are your marketing tools. Our team of designers, developers work together to achieve required result through their hard work.</p>
               <p><b>We value the money and time of our clients:</b> At Guri web Developer , we focus on delivering the best possible business results within the stipulated timeframe and at competitive price without compromising on the quality of the product</p>
               </div>
              
            </div>
          </div>
        </div>

      </div>
    </section>
    </>
  )
}
