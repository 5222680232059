import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {

const top=()=>{

  window.scrollTo({ top: 0, behavior: 'smooth' });
}

  return (
    <>
      


      <footer id="footer">

<div className="footer-top">
  <div className="container">
    <div className="row">

      <div className="col-lg-3 col-md-6 footer-contact">
        <h3>Guri Web Developer</h3>
       
        <p>
        <i className="bx bx-map "></i>  Anupgarh
          Sri Ganganagar<br/> Rajasthan 335701
          India <br/><br/>
          <strong>  <i className="bx bx-phone-call"></i></strong> +91 8696671521<br/>
          <strong><i className="bx bx-envelope"></i></strong> Guri77566@gmail.com<br/>
        </p>
      </div>

      <div className="col-lg-2 col-md-6 footer-links">
        <h3>  <i className="bi bi-link-45deg"></i></h3>
        <ul>
          <li><i className="bx bx-chevron-right"></i> <Link to="/about" onClick={top}>About us</Link></li>
          <li><i className="bx bx-chevron-right"></i> <Link to="/" onClick={top}>Home</Link></li>
          <li><i className="bx bx-chevron-right"></i> <Link to="/services"onClick={top}>Services</Link></li>
          <li><i className="bx bx-chevron-right"></i> <Link to="/term-condition"onClick={top}>Terms of service</Link></li>
          <li><i className="bx bx-chevron-right"></i> <Link to="/privacy"onClick={top}>Privacy policy</Link></li>
      
      </ul>
      </div>

      <div className="col-lg-3 col-md-6 footer-links">
        <h4>Our Services</h4>
        <ul>
          <li><i className="bx bx-chevron-right"></i> <Link onClick={top} to="/services">Web Design</Link></li>
          <li><i className="bx bx-chevron-right"></i> <Link onClick={top} to="/services">Web Development</Link></li>
          <li><i className="bx bx-chevron-right"></i> <Link onClick={top} to="/services">Mobile Applications</Link></li>
          <li><i className="bx bx-chevron-right"></i> <Link onClick={top} to="/services">SEO (Search Engine Optimization)</Link></li>
          <li><i className="bx bx-chevron-right"></i> <Link onClick={top} to="/services">E-Commerce Solutions</Link></li>
        </ul>
      </div>

      <div className="col-lg-4 col-md-6 footer-newsletter">
        <h4>Join Us </h4>
        
        <form action="" >
          <input type="email" name="email"/><input type="submit" value="Subscribe"/>
        </form>
      </div>

    </div>
  </div>
</div>

<div className="container  py-3 text-center ">

  <div className="">
    <div className="copyright">
      &copy; Copyright <strong><span>Guri Web Developer</span></strong>. All Rights Reserved
    </div>
    <div className="credits">
     
    </div>
  </div>

</div>
</footer>



    </>
  )
}
