import React from 'react'

export default function Services() {
  return (
    <>
    <br/><br/><br/><br/>
      <section id="services" className="services">
      <div className="container">

        <div className="section-title">
          <h2>Services</h2>
          <p>We offer a wide array of services to cater to any of your web, mobile, or digital marketing requirements. Check out below:</p>
        </div>

        <div className="row">

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box iconbox-blue">
              <div className="icon">
              <i className="bi bi-cart-plus-fill"></i>
              </div>
              <h4><a href="">E-Commerce Solutions</a></h4>
              <p>we help you build your online store without a hitch by integrating popular platforms and frameworks, teamed with our innovative strategies. There are many CMS option available for your ecommerce website or we can make a customized website. Also, you may hire to develop impressive Market Place B2B and enable your users to buy or sell goods & services online. Our ecommerce solutions will help your business attract more customers.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in"
            data-aos-delay="200">
<div className="icon-box iconbox-yellow">
              <div className="icon">
              <i className="bi bi-search"></i>
              </div>
              <h4><a href="">SEO (Search Engine Optimization)</a></h4>
              <p>We are a team of Web Design and INTERNET Marketing professionals who provides the Best SEO services with quality results. A good business comes from great marketing, one of the oldest and greatest says of all the time. We started SEO services with affordable online promotion packages which can be illustrated from the bottom.</p>
            </div>


            
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in"
            data-aos-delay="300">
            <div className="icon-box iconbox-pink">
              <div className="icon">
              <i className="bi bi-rocket-takeoff-fill"></i>
              </div>
              <h4><a href="">Digital Marketing</a></h4>
              <p>With the number of active internet users increasing, it has become important for all companies to tap into the digital market to increase their revenue. People are using their smartphones more than ever and by targeting them, a lot of business opportunities can be explored. If you are starting a business, digital marketing can provide you with a thrust or if you have a business, you can expand your business.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
          <div className="icon-box iconbox-orange ">
              <div className="icon">
              <i className="bi bi-laptop-fill"></i>
              </div>
              <h4><a href="">Fully Responsive Website</a></h4>
              <p>As more people are shifting from desktop to mobile, it has become essential for website to be developed as mobile responsive. All our designs are responsive in nature i.e. they very well work on different types of devices and platform like mobile phones, tablets etc. and perfectly blend with the custom functionalities</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box iconbox-red">
              <div className="icon">
              <i className="bi bi-phone-fill"></i>
              </div>
              <h4><a href="">Mobile Applications</a></h4>
              <p>In this era of smartphones and mobile phones, experience of browsing the internet has completely transformed. We are India's most trusted Mobile Application development service providers. We convert your vision into reality. Build a tailor-made mobile app to deliver experiences aggressively.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box iconbox-teal">
              <div className="icon">
              <i className="bi bi-pc-display"></i>
              </div>
              <h4><a href="">Dynamic Website</a></h4>
              <p>Dynamic websites are editable web pages. The user can easily modify these types of websites/web pages without any web design development or any technical knowledge. We provide various affordable dynamic website design packages as per your requirements and budget.</p>
            </div>
          </div>

        </div>

      </div>
    </section>
    </>
  )
}
