import React from 'react'
import Herolink from '../img/hero-bg.jpg'
import Aboutlink from '../img/about.jpg'
import php from "../img/homeimg/php.png";
import js from "../img/homeimg/js.png";
import boot from "../img/homeimg/boot.png";
import css from "../img/homeimg/css.jpg";
import jq from "../img/homeimg/jquery.png";
import reactlink from "../img/homeimg/react.png";
import { Link } from 'react-router-dom';
export default function Home() {
    const link=`url(${Herolink}) top center`
  return (
    <>
    <section id="hero" style={{ background: link}} className="d-flex align-items-center justify-content-center">
    <div className="container position-relative">
      <h1>Welcome to Guri Web Developer</h1>
      <h2>We are team of talented designers & developers making powerful websites </h2>
      <Link to="/contact" className="btn-get-started scrollto">Get Started</Link>
    </div>
  </section>

  <main id="main">
    


    <section id="clients" className="clients section-bg">
      <div className="container">

        <div className="row">

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={reactlink} className="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={php} className="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={js} className="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={jq} className="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={boot} className="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={css} className="img-fluid" alt=""/>
          </div>

        </div>

      </div>
    </section>
    <section id="about" className="about">
      <div className="container">

        <div className="row">
          <div className="col-lg-6">
            <img src={Aboutlink} style={{borderRadius:"20px",
             }} className="img-fluid" alt=""/>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <h3>Creating The Website of Your Dreams</h3>
            <p>

            
            </p>
            <div className="row">
              <div className="col-md-12">
                <i className="bx bx-receipt"></i>
                
              <p>Guri web developer is a leading website designing company in rajasthan that offers one-stop business solutions. We take great satisfaction in providing a wide range of services, such as website design, website development, e-commerce development, digital marketing, performance marketing, domain hosting, graphic design, and bulk messaging services.</p>
              <  p>Our management procedures work to guarantee prompt delivery, client contentment, creative solutions, and top-notch services. With top-tier technologies, we have excelled</p>
              </div>
              
            </div>
          </div>
        </div>

      </div>
    </section>
    <br/>
    <section id="cta" className="cta">
      <div className="container">

        <div className="text-center">
          <h3>Call To Action</h3>
          <p> Contact Us For Any Query We Lived Up To Your Expectations Give Us a Chance To Serve </p>
          <Link className="cta-btn" to="/contact">Call To Action</Link>
        </div>

      </div>
    </section>
    <br/>
    
    </main>

    </>
  )
}
