import React from 'react'
import port1 from '../img/port1.jpg'
import port2 from '../img/port2.jpg'
import port3 from '../img/port3.jpg'
export default function Portfolio() {
  return (
    <>
    <br/><br/><br/><br/>
      <section id="portfolio" className="portfolio">
      <div className="container">

        <div className="section-title">
          <h2>Portfolio</h2>
         <p></p>
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li data-filter="*" className="filter-active">All</li>
              <li data-filter=".filter-app">App</li>
              <li data-filter=".filter-card">Card</li>
              <li data-filter=".filter-web">Web</li>
            </ul>
          </div>
        </div>

        <div className="row portfolio-container">

          <div className="col-lg-4 col-md-6 portfolio-item filter-app">
            <img src={port1} className="img-fluid" alt="" width="400px" height="400px"/>
            
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <img src={port2} className="img-fluid" alt=""/>
            
          </div>

          <div className="col-lg-4 col-md-6 portfolio-item filter-app">
            <img src={port3} className="img-fluid" alt=""/>
            
          </div>

          

        </div>

      </div>
    </section>
    </>
  )
}
