import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import Imglink from '../img/logo.png'

export default function Header() {

 const menuhide= (e)=>{

  document.getElementById('navbar').classList.toggle('navbar-mobile');
  e.target.classList.toggle('bi-list')
  e.target.classList.toggle('bi-x')



 }
 const top1=()=>{
const e = document.getElementById("menubtn");
  document.getElementById('navbar').classList.remove('navbar-mobile');
  e.classList.remove('bi-x')
  e.classList.add('bi-list')
 


  window.scrollTo({ top: 0, behavior: 'smooth' });
}







  return (
    <>
   
<header id="header" className="fixed-top ">
    <div className="container d-flex align-items-center justify-content-between">
      <img src={Imglink} alt="" style={{borderRadius:"11px"}} width="70px"/>
      <h2 className="logo">
        
        <Link to="/">Guri Web Developer</Link></h2>
      
      <nav id="navbar" className="navbar">
        <ul>
          <li><NavLink onClick={top1} className="nav-link scrollto " to="/">Home</NavLink></li>
          <li><NavLink onClick={top1} className="nav-link scrollto" to="about">About</NavLink></li>
          <li><NavLink onClick={top1} className="nav-link scrollto" to="services">Services</NavLink></li>
          <li><NavLink onClick={top1} className="nav-link scrollto " to="portfolio">Portfolio</NavLink></li>
         
          <li><NavLink onClick={top1} className="nav-link scrollto" to="pricing">Pricing</NavLink></li>
          <li><NavLink onClick={top1} className="nav-link scrollto" to="contact">Contact</NavLink></li>
        </ul>
        <i id="menubtn" className="bi bi-list mobile-nav-toggle" onClick={(e)=>menuhide(e)} >
       
        </i>
        
      </nav>

    </div>
  </header>
    
    </>
  )
}
